import React, { useState, forwardRef, useEffect } from "react";
import Proptypes from "prop-types";
import {
	Button,
	Link,
	Text,
	VerticalList,
	Icon,
	Accordion,
	Separator,
	CallUsModal,
	AuthenticationModal,
} from "@website-builder/ui/shared/elements";
import CourseMenu from "./CourseMenu";
import Dropdown from "./Dropdown";
import FocusLock from "react-focus-lock";
import {
	AncillaryNav,
	AncillaryNavActions,
	Caret,
	DropdownLabelWrapper,
	NavActions,
	NavBar,
	NavBrand,
	NavHeaderWrapper,
	NavMenu,
	NavMenuMobile,
	NavMenuMobileFooter,
	NavWrapper,
	PencilBannerWrapper,
	UserAvatarWrapper,
} from "./style";
import { useMediaQuery } from "@react-hook/media-query";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import {
	getXCSRFToken,
	addToDataLayer,
} from "@website-builder/utilities/utils/utils.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
import MegaCourseMenu from "./MegaCourseMenu";

const DropdownLabel = forwardRef(
	({ label, color, lightHeader, ...restProps }, ref) => (
		<DropdownLabelWrapper
			ref={ref}
			lightHeader={lightHeader}
			aria-haspopup="dialog"
			{...restProps}
		>
			<Text variant="paragraph_S_bold" color={color}>
				{label}
			</Text>
			<Caret color={color} aria-hidden="true" {...restProps} />
		</DropdownLabelWrapper>
	),
);
const initialDropdownState = {
	dropdown1: false,
	dropdown2: false,
	dropdown3: false,
	dropdown4: false,
};

const initialHeaderStyle = {
	backgroundColor: "transparent",
	visibility: "visible",
	opacity: 1,
};
const isB2U = isBusinessVerticalB2U();
const NavigationHeader = (props) => {
	const {
		courseMenu,
		whySpringboardItems,
		forPartnersItems,
		resourcesItems,
		additionalLinks,
		callUsLabel,
		callUsModalDetails,
		loginLabel,
		paidLander,
		apiBaseUrl,
		globalHostName,
		isStaticBuild,
		setUserDetailsProp,
		isHeaderSticky,
		isHeaderLight,
		pageSection,
		headerCTA: cta,
		pencilBanner,
		context,
	} = props;

	const handleDropdownClick = (label) => {
		// data event start
		addToDataLayer({
			event: "header_nav_menu_item_click",
			click_text: label,
		});
		// data event end
	};

	const [isOpen, setIsOpen] = useState(initialDropdownState);
	const [headerStyle, setHeaderStyle] = useState(initialHeaderStyle);
	const [lightHeader, setLightHeader] = useState(isHeaderLight);
	const [mobileMenuIcon, setMobileMenuIcon] = useState("hamburger");
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [userAvatar, setUserAvatar] = useState(null);
	const [showUserMenu, setShowUserMenu] = useState(false);
	const [showMentorDashboard, setShowMentorDashboard] = useState(false);
	const [showCareerDashboard, setShowCareerDashboard] = useState(false);
	const [showMyCourses, setShowMyCourses] = useState(false);
	const [showMyLearning, setShowMyLearning] = useState(false);
	const [isStudent, setIsStudent] = useState(false);
	const [showCallUsModal, setShowCallUsModal] = useState(false);
	const satisfiesMobileWidth = useMediaQuery(`(max-width: ${TABLET_M_BP}px)`);
	const [isMobile, setIsMobile] = useState(true);
	const [showPencilBanner, setShowPencilBanner] = useState(
		pencilBanner?.text ? true : false,
	);

	useEffect(() => {
		setIsMobile(satisfiesMobileWidth);
	}, [satisfiesMobileWidth]);

	const headerBackground = !lightHeader
		? "var(--primary-neutral-white)"
		: "var(--primary-forest-600)";
	const userApiEndpoint = `${apiBaseUrl}/user/`;
	const signOutApiEndPoint = `${apiBaseUrl}/accounts/logout/`;

	const handleCallUsModalLink = (event) => {
		event.preventDefault();
		setShowCallUsModal(true);
	};

	useEffect(() => {
		fetch(userApiEndpoint, {
			credentials: "include",
		})
			.then((res) => res.json())
			.then((response) => {
				const { code } = response;
				setUserDetailsProp(response);
				if (code === 200) {
					const {
						firstname,
						picture,
						is_admin,
						is_mentor,
						technical_coach_id,
						behavioural_coach_id,
						redirect_link,
						lpc = true,
					} = response;

					if (!lpc) {
						window.location.href = "/password-reset/";
					} else if (
						(window.location.pathname === "/" ||
							window.location.pathname === "/home") &&
						redirect_link !== "/"
					) {
						window.location.href = globalHostName + redirect_link;
					}
					const isStudentCheck = !(
						is_mentor ||
						is_admin ||
						behavioural_coach_id ||
						technical_coach_id
					);
					if (isStudentCheck) {
						setIsStudent(true);
					}
					if (is_admin || is_mentor) {
						setShowMentorDashboard(true);
					}
					if (technical_coach_id || behavioural_coach_id) {
						setShowCareerDashboard(true);
					}
					if (is_mentor) {
						setShowMyCourses(true);
					} else {
						setShowMyLearning(true);
					}
					setUserAvatar({
						firstname,
						picture,
					});
				} else if (
					isStaticBuild &&
					code === 500 &&
					window.location.pathname.includes("/refer")
				) {
					window.location.href = globalHostName + "/login/";
				}
			})
			.catch((err) => {
				setUserAvatar(null);
			});

		const scrollHandler = () => {
			if (window.scrollY <= 32) {
				if (!showMobileMenu) {
					//header at top, transparent background
					setHeaderStyle({ ...headerStyle, backgroundColor: "transparent" });
					setLightHeader(isHeaderLight);
				}
			} else {
				//else, white background
				setHeaderStyle({
					...headerStyle,
					backgroundColor: "var(--primary-neutral-white)",
				});
				setLightHeader(false);
			}
		};
		if (isHeaderSticky) {
			window.addEventListener("scroll", scrollHandler);
			return () => {
				window.removeEventListener("scroll", scrollHandler);
			};
		}
	}, [showMobileMenu]);

	const signOut = async () => {
		// Using try catch as the api response is a redirect to the root domain
		try {
			await fetch(signOutApiEndPoint, {
				credentials: "include",
				redirect: "manual",
			});
		} catch (err) {
			console.error("SIGNOT", err);
		}

		setUserAvatar(null);
	};

	const closeDropDown = React.useCallback(
		(menuIndex) => {
			if (isOpen[`dropdown${menuIndex}`])
				setIsOpen({ ...isOpen, [`dropdown${menuIndex}`]: false });
		},
		[isOpen],
	);

	const openDropDown = (menuIndex) => {
		const val = !isOpen[`dropdown${menuIndex}`];
		setIsOpen({ ...initialDropdownState, [`dropdown${menuIndex}`]: val });
	};

	useEffect(() => {
		const handleEsc = (e) => {
			if (e.key === "Escape") {
				setIsOpen(initialDropdownState);
			}
		};
		window.addEventListener("keydown", handleEsc);
		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, []);

	const linkListToLinkJSX = (item, menuIndex) => (
		<Link
			className="bold-link"
			href={item?.cta?.link?.link}
			target={item?.cta?.link?.target}
			onClick={(e) => {
				closeDropDown(menuIndex);
				handleCTAClick(
					item.cta,
					{ click_text: item.text, redirect_to: item.cta.link.link },
					{},
					e,
				);
			}}
		>
			<Text
				variant="paragraph_M"
				color={{ base: "primary", color: "neutral", shade: "nightshade-800" }}
			>
				{item.text}
			</Text>
		</Link>
	);

	const whySpringboardJSXList = whySpringboardItems.map((item) =>
		linkListToLinkJSX(item, 2),
	);
	const forPartnersJSXList = forPartnersItems.map((item) =>
		linkListToLinkJSX(item, 3),
	);
	const resourcesJSXList = resourcesItems.map((item) =>
		linkListToLinkJSX(item, 4),
	);

	const accordionMenu = [
		{
			heading: "Courses",
			content: courseMenu?.isMegaMenu ? (
				<MegaCourseMenu
					{...courseMenu}
					isMobile={true}
					pageSection={pageSection}
					context={context}
				/>
			) : (
				<CourseMenu
					{...courseMenu}
					pageSection={pageSection}
					isMobile={true}
					context={context}
					onClose={() => {
						setShowMobileMenu(false);
						setMobileMenuIcon("hamburger");
					}}
				></CourseMenu>
			),
		},
		{
			heading: "Why Springboard",
			content: <VerticalList listItems={whySpringboardJSXList}></VerticalList>,
		},
		{
			heading: "For Partners",
			content: <VerticalList listItems={forPartnersJSXList}></VerticalList>,
		},
		{
			heading: "Resources",
			content: <VerticalList listItems={resourcesJSXList}></VerticalList>,
		},
	];

	const handleHamburgerClick = () => {
		if (mobileMenuIcon === "hamburger") {
			setHeaderStyle({ ...headerStyle, backgroundColor: headerBackground });
			setMobileMenuIcon("xMark32");
		} else {
			setHeaderStyle({
				...headerStyle,
				backgroundColor:
					window.scrollY <= 32 ? "transparent" : "var(--primary-neutral-white)",
			});
			setMobileMenuIcon("hamburger");
		}

		const val = !showMobileMenu;
		setShowMobileMenu(val);
	};

	const brandLogoType = !lightHeader ? "sb-dark" : "sb-light";

	const textColor = !lightHeader
		? { base: "primary", color: "neutral", shade: "nightshade-800" }
		: { base: "primary", color: "neutral", shade: "white" };

	const buttonJSX = cta ? (
		<Button
			buttonConfiguration={isB2U && cta}
			darkBg={!isMobile ? lightHeader : false}
			buttonText={cta?.buttonText || "Apply Now"}
			type={cta?.type || "primary"}
			onClick={() => {
				if (showMobileMenu) {
					setShowMobileMenu(false);
					setMobileMenuIcon("hamburger");
				}
				handleCTAClick(cta, {
					page_section: pageSection,
					click_text: cta?.buttonText,
					redirect_to: cta?.link?.link,
				});
			}}
		/>
	) : null;

	const callUsJSX = callUsLabel ? (
		<>
			<Link
				darkBg={!isMobile ? lightHeader : false}
				iconConfig={{ iconName: "call" }}
				onClick={handleCallUsModalLink}
				className="call-us-link"
				as="button"
				aria-haspopup="dialog"
				aria-controls={
					showCallUsModal ? "ancillary-nav-header-call-us-modal" : null
				}
				aria-expanded={showCallUsModal}
			>
				<Text
					variant="paragraph_S_bold"
					color={
						isMobile
							? { base: "primary", color: "neutral", shade: "nightshade-800" }
							: textColor
					}
				>
					Call us {callUsLabel}
				</Text>
			</Link>
		</>
	) : null;

	const userAvatarJSX = (
		<UserAvatarWrapper
			onClick={() => setShowUserMenu(!showUserMenu)}
			showUserMenu={showUserMenu}
		>
			<div className="user-info-wrapper">
				<div className="imageWrapper">
					<img src={userAvatar?.picture} />
				</div>
				<div className="userName">
					<Text variant="paragraph_S_bold">{userAvatar?.firstname}</Text>
				</div>
			</div>
			<div className="userMenuWrapper">
				<div className="backdrop" onClick={() => setShowUserMenu(false)}></div>
				<div className="userMenu">
					{showMentorDashboard && (
						<a href="/mentor-dashboard">Mentor Dashboard</a>
					)}
					{showCareerDashboard && (
						<a href="/career-dashboard">Coach Dashboard</a>
					)}
					{showMyLearning && <a href="/profile/#/my-learning">My Learning</a>}
					{showMyCourses && <a href="/profile/#/my-courses">My Courses</a>}
					<Separator darkBg />
					<a href="/profile">Profile</a>
					<a href="/profile/#/billing">My Account</a>
					<a href="/profile/#/settings">Settings</a>
					<Separator darkBg />
					<span onClick={signOut}>Sign Out</span>
				</div>
			</div>
		</UserAvatarWrapper>
	);

	return (
		<NavHeaderWrapper
			isPencilBannerClosed={!showPencilBanner}
			className="navigation-header"
		>
			<FocusLock disabled={!showMobileMenu}>
				{showPencilBanner ? (
					<PencilBannerWrapper
						stickyHeader={isHeaderSticky}
						bgColor={pencilBanner?.backgroundColor?.color}
						textColor={pencilBanner?.textColor?.color}
						hasIcons={pencilBanner?.hasIcons}
					>
						<div className="pencil-banner-text">
							{pencilBanner?.hasIcons && (
								<Icon
									iconName="checkCircle"
									stroke={pencilBanner?.textColor?.color || "#C3F570"}
									style={{ strokeWidth: 1.5 }}
									alt="checkIcon"
								/>
							)}
							<Text variant="paragraph_M_bold">{pencilBanner?.text}</Text>
							{pencilBanner?.hasIcons && (
								<Icon
									iconName="checkCircle"
									stroke={pencilBanner?.textColor?.color || "#C3F570"}
									style={{ strokeWidth: 1.5 }}
									alt="checkIcon"
								/>
							)}
						</div>
						{pencilBanner?.hasCloseButton ? (
							<button
								className="close-button-wrapper"
								onClick={() => {
									setShowPencilBanner(false);
								}}
							>
								<Icon
									iconName="xMark24"
									stroke={pencilBanner?.textColor?.color || "#C3F570"}
									fill={pencilBanner?.textColor?.color || "#C3F570"}
									alt="close modal"
								/>
							</button>
						) : null}
					</PencilBannerWrapper>
				) : null}
				<NavWrapper
					style={headerStyle}
					lightHeader={lightHeader}
					stickyHeader={isHeaderSticky}
					showMobileMenu={showMobileMenu}
					isPencilBannerClosed={!showPencilBanner}
				>
					<AncillaryNav className="ancillary-nav" lightHeader={lightHeader}>
						<AncillaryNavActions>
							{callUsJSX}
							{!userAvatar ? (
								<Link
									darkBg={lightHeader}
									iconConfig={{ iconName: "avatar" }}
									onClick={() => setShowLoginModal(true)}
									className="header-login-link"
									as="button"
									aria-haspopup="dialog"
									aria-expanded={showLoginModal}
									aria-controls="authentication-login-modal"
								>
									<Text variant="paragraph_S_bold" color={textColor}>
										{loginLabel}
									</Text>
								</Link>
							) : (
								userAvatarJSX
							)}
						</AncillaryNavActions>
					</AncillaryNav>
					<AuthenticationModal
						className="authentication-modal"
						show={showLoginModal}
						apiBaseUrl={apiBaseUrl}
						globalHostName={globalHostName}
						isStaticBuild={isStaticBuild}
						getXCSRFToken={getXCSRFToken}
						handleModalClose={() => setShowLoginModal(false)}
						loginApiEndPoint={`${apiBaseUrl}/accounts/login/`}
					/>
					<NavBar className="navigation-header-main-nav">
						<NavBrand>
							<Link href="/" aria-label="Go to Homepage">
								<Icon iconName={brandLogoType} className="brand-logo" />
							</Link>
						</NavBrand>
						{!paidLander && (
							<>
								<NavMenu
									className="navigation-header-desktop-menu"
									lightHeader={lightHeader}
								>
									<Dropdown
										ctaComponent={
											<DropdownLabel
												label="Courses"
												color={textColor}
												open={isOpen.dropdown1}
												lightHeader={lightHeader}
												aria-expanded={isOpen.dropdown1}
												aria-controls="nav-header-course-menu"
												onClick={() => {
													openDropDown(1);
													handleDropdownClick("Courses"); // data event call
												}}
											/>
										}
										content={
											courseMenu?.isMegaMenu ? (
												<MegaCourseMenu
													{...courseMenu}
													pageSection={pageSection}
													context={context}
												></MegaCourseMenu>
											) : (
												<CourseMenu
													{...courseMenu}
													pageSection={pageSection}
													resetMenu={!isOpen.dropdown1}
													context={context}
													onClose={() => {
														closeDropDown(1);
													}}
												></CourseMenu>
											)
										}
										contentId="nav-header-course-menu"
										isContentMegaMenu={courseMenu?.isMegaMenu}
										onClose={() => {
											closeDropDown(1);
										}}
										open={isOpen.dropdown1}
										overlayTop={25}
										contentTop={25}
									/>
									<Dropdown
										ctaComponent={
											<DropdownLabel
												label="Why Springboard"
												color={textColor}
												open={isOpen.dropdown2}
												lightHeader={lightHeader}
												aria-expanded={isOpen.dropdown2}
												aria-controls="nav-header-why-springboard-menu"
												onClick={() => {
													openDropDown(2);
													handleDropdownClick("Why Springboard"); // data event call
												}}
											/>
										}
										content={
											<VerticalList
												style={{ padding: "40px" }}
												listItems={whySpringboardJSXList}
											></VerticalList>
										}
										contentId="nav-header-why-springboard-menu"
										onClose={() => {
											closeDropDown(2);
										}}
										open={isOpen.dropdown2}
										overlayTop={25}
										contentTop={25}
									/>
									<Dropdown
										ctaComponent={
											<DropdownLabel
												label="For Partners"
												color={textColor}
												open={isOpen.dropdown3}
												lightHeader={lightHeader}
												aria-expanded={isOpen.dropdown3}
												aria-controls="nav-header-for-partners-menu"
												onClick={() => {
													openDropDown(3);
													handleDropdownClick("For Partners"); // data event call
												}}
											/>
										}
										content={
											<VerticalList
												style={{ padding: "40px" }}
												listItems={forPartnersJSXList}
											></VerticalList>
										}
										contentId="nav-header-for-partners-menu"
										onClose={() => {
											closeDropDown(3);
										}}
										open={isOpen.dropdown3}
										overlayTop={25}
										contentTop={25}
									/>
									<Dropdown
										ctaComponent={
											<DropdownLabel
												label="Resources"
												color={textColor}
												open={isOpen.dropdown4}
												lightHeader={lightHeader}
												aria-expanded={isOpen.dropdown4}
												aria-controls="nav-header-resources-menu"
												onClick={() => {
													openDropDown(4);
													handleDropdownClick("Resources"); // data event call
												}}
											/>
										}
										content={
											<VerticalList
												style={{ padding: "40px" }}
												listItems={resourcesJSXList}
											></VerticalList>
										}
										contentId="nav-header-resources-menu"
										onClose={() => {
											closeDropDown(4);
										}}
										open={isOpen.dropdown4}
										overlayTop={25}
										contentTop={25}
									/>
									{additionalLinks?.length
										? additionalLinks?.map((item, index) => (
												<Link
													className="nav-additional-link"
													href={item.cta?.link?.link}
													target={item.cta?.link?.target}
													key={index}
													onClick={(e) => {
														handleCTAClick(
															item.cta,
															{
																click_text: item.text,
																redirect_to: item.cta.link.link,
															},
															{},
															e,
														);
													}}
												>
													<Text variant="paragraph_S_bold" color={textColor}>
														{item.text}
													</Text>
												</Link>
											))
										: null}
								</NavMenu>
							</>
						)}

						<NavActions className="desktop-nav-action">
							{" "}
							{buttonJSX}{" "}
						</NavActions>
						<NavActions className="mobile-nav-action">
							{userAvatar ? userAvatarJSX : null}
							<Icon
								as="button"
								className="nav-hamburger-icon"
								aria-label="mobile menu open/close"
								onClick={handleHamburgerClick}
								iconName={mobileMenuIcon}
								aria-hidden="false"
								fill={
									!lightHeader
										? "var(--primary-neutral-nightshade-800)"
										: "var(--primary-neutral-white)"
								}
							/>
						</NavActions>
					</NavBar>
					<NavMenuMobile
						className="navigation-header-mobile-menu"
						showMenu={showMobileMenu}
					>
						{!paidLander && showMobileMenu && (
							<>
								<Accordion
									accordionItems={accordionMenu}
									onExpand={handleDropdownClick}
									icon="caret"
								/>
								<div className="accordion-additional-links">
									{additionalLinks?.length
										? additionalLinks?.map((item, index) => (
												<>
													<Link
														className="accordion-additional-link"
														href={item.cta?.link?.link}
														target={item.cta?.link?.target}
														key={index}
														onClick={(e) => {
															handleCTAClick(
																item.cta,
																{
																	click_text: item.text,
																	redirect_to: item.cta.link.link,
																},
																{},
																e,
															);
														}}
													>
														<Text variant="headline_6" color={textColor}>
															{item.text}
														</Text>
													</Link>
													<Separator />
												</>
											))
										: null}
								</div>
							</>
						)}

						<NavMenuMobileFooter>
							{callUsJSX}
							<div className="footerButtons">
								{buttonJSX}
								<Button
									buttonText="Login"
									type="secondary"
									onClick={() => setShowLoginModal(true)}
									className="header-login-link"
									aria-haspopup="dialog"
									aria-expanded={showLoginModal}
									aria-controls="authentication-login-modal"
								/>
							</div>
						</NavMenuMobileFooter>
					</NavMenuMobile>
					<CallUsModal
						showModal={showCallUsModal}
						onClose={() => setShowCallUsModal(false)}
						tel={callUsLabel}
						{...callUsModalDetails}
						imageUrl={callUsModalDetails?.image?.image}
						imageAltText={callUsModalDetails?.image?.altTag}
						pageSection="NavigationHeader"
						id="ancillary-nav-header-call-us-modal"
					/>
				</NavWrapper>
			</FocusLock>
		</NavHeaderWrapper>
	);
};

const LinkType = Proptypes.shape({
	value: Proptypes.string,
	link: Proptypes.string,
	target: Proptypes.oneOf(["_self", "_blank"]),
	isDisabled: Proptypes.bool,
});

NavigationHeader.propTypes = {
	courseMenu: Proptypes.object,
	whySpringboardItems: Proptypes.array,
	forPartnersItems: Proptypes.array,
	resourcesItems: Proptypes.array,
	callUsLabel: Proptypes.string,
	loginLabel: Proptypes.string,
	cta: Proptypes.object,
	paidLander: Proptypes.bool,
	lightHeader: Proptypes.bool,
	pageSection: Proptypes.string,
};

NavigationHeader.defaultProps = {
	courseMenu: {},
	whySpringboardItems: [],
	forPartnersItems: [],
	resourcesItems: [],
	callUsLabel: "",
	loginLabel: "Login",
	cta: null,
	paidLander: false,
	lightHeader: false,
	pageSection: "",
};

export default NavigationHeader;
