import {
	DESKTOP_SM_BP,
	TABLET_M_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

export const NavHeaderWrapper = styled.div`
	height: ${({ isPencilBannerClosed }) => (isPencilBannerClosed ? 0 : "56px")};
`;
export const PencilBannerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: ${({ bgColor }) => (bgColor ? bgColor : "#0E5642")};
	padding: 0px 24px;
	${({ stickyHeader }) =>
		stickyHeader &&
		`
        position: fixed; 
        z-index: 20;
    `}

	.pencil-banner-text {
		width: 100%;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 8px 0;
		padding-right: 16px;
		overflow: auto;
		p {
			color: ${({ textColor }) => (textColor ? textColor : "#C3F570")};
			margin: ${({ hasIcons }) => hasIcons && "0 6px"};
		}
		a {
			color: ${({ textColor }) =>
				textColor ? textColor : "#C3F570"} !important;
		}
	}

	.close-button-wrapper {
		background: transparent;
		border: none;
		box-shadow: none;
		cursor: pointer;
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 0px 16px;
	}

	.pencil-banner {
		background-color: #fff;
	}
`;

export const NavWrapper = styled.header`
	width: 100%;
	${({ stickyHeader }) =>
		stickyHeader
			? `
        position: fixed; 
        transition: opacity 0.2s ease-out, background-color 0.2s ease-out;
    `
			: `
        position: absolute;
    `}
	top: ${({ isPencilBannerClosed }) => (isPencilBannerClosed ? 0 : "56px")};
	z-index: 20;
	${({ showMobileMenu }) =>
		showMobileMenu &&
		`
    height: 100vh;
    overflow: auto;
    overscroll-behavior: contain;
    `}
`;

export const AncillaryNav = styled.div`
	height: 32px;
	width: 100%;
	border-bottom: 1px solid
		${({ lightHeader }) =>
			!lightHeader ? "rgba(5, 8, 43, 0.6)" : "rgba(237, 239, 238, 0.5)"};
	display: flex;
	justify-content: flex-end;
	align-items: center;
	top: 0;
	z-index: 20;
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		display: none;
	}
`;

export const AncillaryNavActions = styled.div`
	display: flex;
	gap: 1.5rem;
	margin-right: 6rem;

	.header-login-link,
	.call-us-link {
		background: transparent;
		border: none;
		box-shadow: none;
	}
`;

export const NavBar = styled.nav`
	height: 72px;
	width: 100%;
	display: flex;
	gap: 3rem;
	justify-content: space-between;
	align-items: center;
	top: 0;
	z-index: 20;
	.mobile-nav-action {
		display: none;
	}
	.desktop-nav-action {
		display: block;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.mobile-nav-action {
			display: block;
		}
		.desktop-nav-action {
			display: none;
		}
		.navigation-header-desktop-menu {
			display: none;
			margin-right: 1.5rem;
		}
	}
`;

export const NavBrand = styled.div`
	width: fit-content;
	margin-left: 6rem;
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		margin-left: 1.5rem;
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP}px) and (min-width: ${TABLET_M_BP}px) {
		margin-left: 4rem;
	}

	.brand-logo {
		& > svg {
			height: 30px;
			width: 174px;
		}
	}
`;

export const NavMenu = styled.div`
	display: flex;
	gap: 2.5rem;

	.bold-link:hover,
	.bold-link:focus {
		& p {
			font-weight: var(--fw-haffer-xh-2);
		}
	}
	.nav-additional-link:hover,
	.nav-additional-link:focus {
		cursor: pointer;
		& > * {
			color: ${({ lightHeader }) =>
				!lightHeader
					? "var(--primary-forest-400) !important"
					: "var(--primary-green-400) !important"};
		}
	}
`;

export const NavMenuMobile = styled.div`
	width: 100%;
	min-height: calc(100vh - 71.5px);
	background-color: #fff;
	display: none;

	.accordion-additional-links > div {
		display: block;
		padding: 24px;
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		display: ${({ showMenu }) => (showMenu ? "block" : "none")};

		.accordion-additional-link > h6 {
			color: var(--primary-neutral-nightshade-800) !important;
		}
	}
`;
export const NavMenuMobileFooter = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	padding: 24px;
	gap: 3rem;

	.call-us-link {
		background: transparent;
		border: none;
		box-shadow: none;
	}

	& .footerButtons {
		display: flex;
		gap: 24px;
		width: 100%;
		& button {
			width: 100%;
		}
	}
`;

export const NavActions = styled.div`
	.nav-hamburger-icon {
		background: transparent;
		border: none;
		box-shadow: none;
		padding: 0;
	}

	width: max-content;
	display: flex;
	align-items: center;
	gap: 3rem;
	margin-right: 6rem;
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		gap: 2rem;
		margin-right: 1.5rem;
	}
`;

export const DropdownLabelWrapper = styled.button`
	background: transparent;
	border: none;
	text-align: left;
	padding: 0;

	position: relative;
	display: flex;
	align-items: center;
	gap: 4px;

	&:hover,
	&:focus {
		cursor: pointer;
		& > * {
			color: ${({ lightHeader }) =>
				!lightHeader
					? "var(--primary-forest-400) !important"
					: "var(--primary-green-400) !important"};
		}
		& i {
			border-top: 4.5px solid
				${({ lightHeader }) =>
					!lightHeader
						? "var(--primary-forest-400) !important"
						: "var(--primary-green-400) !important"};
		}
	}
`;

export const Caret = styled.i`
	width: 0;
	height: 0;
	display: inline-block;
	border-left: 4.5px solid transparent;
	border-right: 4.5px solid transparent;
	border-top: 4.5px solid
		${({ color }) => `var(--${color.base}-${color.color}-${color.shade})`};
	transform: ${({ open }) => (open ? "rotateX(180deg)" : "rotateX(0deg)")};
	transition: transform 0.5s ease-out;
`;

export const UserAvatarWrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	.user-info-wrapper {
		display: flex;
		flex-direction: row;
		gap: 10px;
		cursor: pointer;
		.imageWrapper {
			border-radius: 25px;
			overflow: hidden;
			height: 20px;
			width: 20px;
		}
		img {
			height: 20px;
		}
	}
	.backdrop {
		display: ${(props) => (props.showUserMenu ? "block" : "none")};
		position: fixed;
		top: 0;
		bottom: 0;
		background: transparent;
		left: 0;
		right: 0;
		z-index: 4;
	}
	.userMenu {
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: -10px;
			width: 0;
			height: 0;
			transform: translateX(-50%);
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #48485e;
		}
		display: ${(props) => (props.showUserMenu ? "block" : "none")};
		position: absolute;
		background-color: #48485e;
		padding: 20px;
		top: 40px;
		left: -80px;
		min-width: 180px;
		z-index: 5;
		border-radius: 16px;
		a,
		span {
			color: var(--primary-neutral-white);
			cursor: pointer;
			display: block;
			font-family: var(--ff-haffer-xh);
			font-weight: var(--fw-haffer-xh-0);
			line-height: var(--lh-5);
			font-size: var(--fs-2);
			letter-spacing: var(--ls-0);
			text-decoration: none;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
		& hr {
			margin: 0;
		}
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		.user-info-wrapper {
			.userName {
				display: none;
			}
			.imageWrapper {
				border-radius: 15px;
				overflow: hidden;
				height: 32px;
				width: 32px;
			}
			img {
				height: 32px;
			}
		}
		.userMenu {
			&:before {
				left: 115px;
			}
			left: -100px;
			top: 50px;
		}
	}
`;
